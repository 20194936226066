var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"background-color":"#fff","is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.flagErr),callback:function ($$v) {_vm.flagErr=$$v},expression:"flagErr"}},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")])],1),(!_vm.flagErr)?_c('section',[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_vm._v(" Fecha: "),_c('b-form-group',[_c('b-form-datepicker',_vm._b({class:{
              'form-control is-invalid': _vm.$v.date.$error,
              'form-control is-valid': !_vm.$v.date.$invalid
            },attrs:{"id":"date","placeholder":"dd/mm/yyyy","date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },"locale":"es-ES","today-button":"","reset-button":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'b-form-datepicker',_vm.labels['es-ES'] || {},false))],1)],1),_c('b-col',{attrs:{"lg":"7"}},[_c('strong',[_vm._v("Zonas:")]),_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","options":_vm.zonesOpts,"name":"flavour-1"},model:{value:(_vm.zonesSelected),callback:function ($$v) {_vm.zonesSelected=$$v},expression:"zonesSelected"}}),_vm._v(" io: "+_vm._s(_vm.walkerIndicator)+" ")],1),_c('b-col',{attrs:{"lg":"2"}},[_c('br'),_c('b-button',{attrs:{"variant":"warning","disabled":this.$v.date.$invalid},on:{"click":_vm.submit}},[_vm._v(" Consultar ")])],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_vm._v(" Servicios agendados: "+_vm._s(_vm.services != null ? _vm.services.length : 0)+", Servicios finalizados "+_vm._s(_vm.servicesFinished.length)+" ")]),_c('b-col',{attrs:{"lg":"12"}},[(_vm.items != null)?_c('Table',{attrs:{"items":_vm.items,"fields":_vm.fields,"walkers":_vm.walkers,"services":_vm.services}}):_vm._e()],1),_c('b-col',{attrs:{"lg":"3"}},[(_vm.servicesNotAssigned != null)?_c('PendingList',{attrs:{"services":_vm.servicesNotAssigned,"walkers":_vm.walkers}}):_vm._e()],1),_c('b-col',{attrs:{"lg":"9"}},[(_vm.clientsToPersuade != null)?_c('ClientsToPersuade',{attrs:{"clients":_vm.clientsToPersuade}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }